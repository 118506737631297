<template>
  <div class="container text-center">
    <h3 class="text-center" style="margin-top: 5%; margin-bottom: 5%">
      ส่งออกข้อมูล
    </h3>
    <div class="row">
      <div class="col">
        <div class="card text-white col-sm" style="background-color: #6392ec">
          <div class="card-body">
            <a
              class="text-white"
              href="http://phdbreport.moph.go.th/hssd1/umd/HSS_HELP/export/export.php"
            >
              <h5 class="text-start card-title">ส่งออกข้อมูลก่อนปี 59</h5>
            </a>
          </div>
        </div>
      </div>
      <div class="col">
        <div
          @click="goto('export-data')"
          class="card pointer text-white col-sm pointer"
          style="background-color: #747af2"
        >
          <div class="card-body">
            <h5 class="text-start card-title">ส่งออกข้อมูล</h5>
          </div>
        </div>
      </div>
      <div v-if="user.role === 1" class="col">
        <div
          @click="goto('formexportdata')"
          class="card text-white col-sm pointer"
          style="background-color: #3eb1b1"
        >
          <div class="card-body">
            <h5 class="text-start card-title">ส่งออกข้อมูลผู้กรอก</h5>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "Report",
  computed: {
    ...mapState({
      user: (state) => {
        return state.Auth.user;
      },
    }),
  },
  data() {
    return {
      expand1: false,
      expand2: false,
      expand3: false,
    };
  },
  created() {
    if (!this.user || this.user.role === 2) {
      this.$router.push({
        path: "/",
      });
    }
  },
  methods: {
    goto(path) {
      this.$router.push({
        path: path,
      });
    },
  },
};
</script>

<style scoped>
card {
  padding: 10px 10px 10px 10px;
}
</style>